/**
 * Foursource page
 * https://foursource.com/signup/ -> https://platform.foursource.com/signup
 */
import * as React from "react";
import { navigate } from "@reach/router";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/pages/redirect";
import Utm from "../components/_global/utm";

// components
import Layout from "../components/layout";
import Redirect from "../components/_page/_redirect";

class SignupRedirectPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  componentDidMount() {
    setTimeout(() => {
      navigate(process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`));
    }, 1000);
  }

  render() {
    return (
      <>
        <Layout pageName="meta" whiteMenu={false}>
          <Redirect text={this.text.redirect} loading={this.text.loading}></Redirect>
        </Layout>
      </>
    );
  }
}

export default withTrans(SignupRedirectPage);
